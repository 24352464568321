import Vue from 'vue'

function find ({ companyId, type }) {
  const path = '/admin/external-integration-installations'
  const params = { companyId, type }
  return Vue.api.get(path, { params })
}

function findOne (...args) {
  return find(...args).then(([doc]) => doc)
}

function _delete ({ id, companyId }) {
  const path = `/admin/external-integration-installations/${id}`
  const params = { companyId }
  return Vue.api.delete(path, { params })
}

export default {
  find,
  findOne,
  delete: _delete
}
