export default [
  {
    path: 'consent/authorize',
    name: 'AdminConsentAuthorize',
    component: () => import('@/views/admin/consent/Authorize.vue')
  },

  {
    path: 'consent/callback',
    name: 'AdminConsentCallback',
    component: () => import('@/views/admin/consent/Callback.vue')
  }
]
