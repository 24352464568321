import Vue from 'vue'

async function find ({ type, companyId }) {
  const path = '/admin/notification-strategy-configurations'
  const params = { companyId, type }
  return Vue.api.get(path, { params })
}

async function findOne (...args) {
  return find(...args).then(([doc]) => doc)
}

async function create ({ type, isEnabled, companyId }) {
  const path = '/admin/notification-strategy-configurations'
  const data = { type, isEnabled }
  const params = { companyId }
  return Vue.api.post(path, { data, params })
}

async function update ({ id, type, isEnabled, companyId }) {
  const path = `/admin/notification-strategy-configurations/${id}`
  const data = { type, isEnabled }
  const params = { companyId }
  return Vue.api.patch(path, { data, params })
}

export default {
  find,
  findOne,
  create,
  update
}
