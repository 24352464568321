import Vue from 'vue'

function load ({
  externalIntegrationInstallationId,
  companyId
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-application`

  const params = { companyId }
  return Vue.api.get(path, { params }).catch((error) => {
    if (error.status === 404) return
    else throw error
  })
}

function create ({
  externalIntegrationInstallationId,
  companyId
} = {}) {
  const path = `/admin/external-integration-installations/${externalIntegrationInstallationId}/external-integration-application`

  const params = { companyId }
  return Vue.api.post(path, { params })
}

export default {
  load,
  create
}
